<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo">

        <b-img
          src="@/assets/images/logo/edubotlong.png"
          alt="logo"
          height="50rem"
          width="185rem"
          class="mt-1"
        />

      </b-link>

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="require('@/assets/images/pages/forgot_password.svg')"
            alt="Forgot password V2"
            v-if="showEmail == true"
          />
          <b-img
            v-else
            fluid
            :src="require('@/assets/images/pages/security.svg')"
            alt="Forgot password V2"
          />
        </div>
      </b-col>

      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
        <b-row>
          <b-col>
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1 text-center"
            >
              Esqueceu sua senha?
            </b-card-title>
          </b-col>
        </b-row>
          <b-row v-show="showEmail" v-if="showEmail == true">
            <b-col> 
              <b-card-text class="mb-2">
                Digite seu usuário e enviaremos instruções para redefinir sua senha
              </b-card-text>
              <validation-observer ref="simpleRules">
                <b-form
                  class="auth-forgot-password-form mt-2"
                  @submit.prevent="sendMail"
                >
                  <b-form-group
                    label="Usuário de acesso"
                    label-for="forgot-password-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required"
                    >
                      <b-form-input
                        id="forgot-password-email"
                        v-model="username"
                        :state="errors.length > 0 ? false:null"
                        name="forgot-password-email"
                        v-on:keyup.enter="sendMail"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-button
                    type="submit"
                    variant="primary"
                    block
                  >
                    Enviar
                  </b-button>
                </b-form>
              </validation-observer>
              <p class="text-center mt-2">
                <b-link :to="{name:'login'}">
                  <feather-icon icon="ChevronLeftIcon" /> Volte ao login
                </b-link>
              </p>
            </b-col>
          </b-row>
          
          <b-row  class="mt-2" v-show="showLoading" v-if="showLoading == true">
            <b-col class="mt-3">
              <div class="mb-5">
                <b-overlay :show="isLoading" rounded="sm" opacity="0.70">
                  <template #overlay>
                    <div class="text-center">
                      <b-icon icon="shield-lock" font-scale="3" animation="cylon" variant="primary" class="mb-1"/>
                      <p id="cancel-label" class="text-primary">
                        Aguarde que estamos validando seus dados...
                      </p>
                    </div>
                  </template>
                </b-overlay>
              </div>
              <small class="mt-4"><p class="text-center">{{menssage}} </p></small>
            </b-col>
          </b-row>

          <b-row v-show="showNewPassword" v-if="showNewPassword == true">
            <b-col>
              <b-card-text class="mb-2">
                Crie uma nova senha
              </b-card-text>
              <div>

              
              <validation-observer ref="simpleRulesPassword">
                <b-form-group
                  label="Nova senha"
                  label-for="forgot-password-new"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="senha"
                    rules="required"
                    vid="Password"
                  >
                    <b-form-input
                      id="forgot-password-new"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      name="forgot-password-new"
                      type="password"
                      placeholder="*************"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Confirme sua senha"
                  label-for="forgot-password-confirmation"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="confime sua senha"
                    rules="required|confirmed:Password"
                  >
                    <b-form-input
                      v-model="password_confirmation"
                      id="forgot-password-confirmation"
                      :state="errors.length > 0 ? false:null"
                      type="password"
                      name="forgot-password-confirmation"
                      placeholder="*************"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mt-2"
                  variant="outline-primary"
                  block
                  @click="newPasswords"
                >
                  Alterar senha
                </b-button>
                
              </validation-observer>
                
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,

      username: null,
      password: null,
      password_confirmation: null,

      menssage: null,

      teste: false,

      isLoading: false,
      
      showEmail: true,
      showLoading: false,
      showNewPassword: false,
    }
  },
  created(){
    this.validacao();
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validacao(){
      if(this.$route.query.token){
        this.showEmail = false,
        this.showNewPassword = true
      }else{
        this.showEmail = true,
        this.showNewPassword = false
      }
    },

    sendMail(paginated = false, page = 1){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data = {
            'username' : this.username,
          }
          localStorage.setItem(
              "forgotPassword", JSON.stringify(data)
            )
          this.isLoading = true
          this.$store.dispatch("auth/sendMails", data)
          .then((response) => { 
            this.menssage = response.data
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Email enviado com sucesso!',
                icon: 'LockIcon',
                text: 'Operação executada com sucesso.',
                variant: 'info'
              }
            })
            this.isLoading = true,
            this.showEmail = false,
            this.showLoading = true
            this.$nextTick(() => this.$refs.simpleRules.reset());
          })
          .finally(() => {
          });
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },


    newPasswords(paginated = false, page = 1){
      this.$refs.simpleRulesPassword.validate().then(success => {
        if (success) {
          let data = {
            'username' : JSON.parse(localStorage.forgotPassword).username,
            'token' : this.$route.query.token,
            'password' : this.password,
            'password_confirmation': this.password_confirmation
          }
          this.isLoading = true
          this.$store.dispatch("auth/newPasswordByToken", data)
          .then((response) => { 
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Senha atualizado com sucesso!',
                icon: 'LockIcon',
                text: 'Operação executada com sucesso.',
                variant: 'info'
              }
            })
            this.$nextTick(() => this.$refs.simpleRulesPassword.reset());
            this.$router.push("/login")
          })
          .finally(() => {
          });
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
